<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 225.6 50" >
        <path d="M193.2,9.5c0,0.7,0,1.3,0,1.9c-0.3,0-0.5,0-0.8,0c0-0.1,0-0.1,0-0.1c0-0.7,0-1.3,0-1.9c0-0.1,0-0.1-0.1-0.1
            c-0.2-0.1-0.4-0.2-0.7-0.3c0.3-0.1,0.5-0.2,0.7-0.3c1.7-0.7,3.5-1.5,5.2-2.2c0.1,0,0.1-0.1,0.2,0c1.9,0.8,3.9,1.6,5.8,2.5
            c0.1,0,0.1,0,0.1,0.1c-0.2,0.1-0.4,0.1-0.5,0.2c-1.8,0.7-3.6,1.6-5.3,2.3c-0.1,0.1-0.1,0.1-0.3,0c-1.3-0.6-2.7-1.2-4.1-1.8
            C193.3,9.6,193.2,9.6,193.2,9.5z" fill="#E83661"/>
        <path d="M201.3,10.7c0,0.6,0,1.1,0,1.6c0,0.4,0,0.8,0,1.2c0,0.1-0.1,0.1-0.1,0.1c-0.8,0.5-1.8,0.9-2.7,1
            c-1.6,0.2-3.2-0.1-4.6-1c-0.1,0-0.1-0.1-0.1-0.1c0-1,0-1.9,0-2.8c0,0,0,0,0-0.1c0.1,0,0.1,0.1,0.1,0.1c1.2,0.5,2.3,1,3.5,1.5
            c0.1,0,0.1,0,0.2,0c1.2-0.5,2.3-1,3.5-1.5C201.2,10.7,201.2,10.7,201.3,10.7z" fill="#E83661"/>
        
        <path d="M88.4,10.5v27.7h-7.7V22.9l-5.3,15.3H69l-5.3-15.4v15.4h-7.7V10.5h9.3l7,18.1l6.8-18L88.4,10.5L88.4,10.5z" fill="#E83661"/>
        <path d="M113.9,28.8H99.3c0.1,1.2,0.4,2.1,1,2.7s1.3,0.9,2.3,0.9c1.3,0,2.2-0.6,2.7-1.7h8.2c-0.4,1.6-1,2.9-2,4.1
            c-1,1.2-2.2,2.1-3.7,2.8c-1.5,0.7-3.1,1-5,1c-2.1,0-4.1-0.4-5.8-1.4c-1.7-0.9-3-2.2-4-3.9c-1-1.7-1.4-3.7-1.4-6s0.4-4.3,1.4-6
            c1-1.7,2.3-3,3.9-3.9c1.7-0.9,3.6-1.4,5.9-1.4c2.1,0,4.1,0.4,5.8,1.3c1.7,0.9,3,2.1,3.9,3.9c1,1.6,1.4,3.6,1.4,5.9
            C113.9,27.6,113.9,28.2,113.9,28.8z M106.1,25c0-1-0.3-1.7-1-2.2c-0.6-0.5-1.4-0.8-2.4-0.8s-1.7,0.2-2.3,0.7
            c-0.6,0.5-1,1.3-1.2,2.3H106.1z" fill="#E83661"/>
        <path d="M117,21.2c0.8-1.7,1.9-3,3.4-3.9c1.4-0.9,3-1.4,4.8-1.4c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4V9.1h7.8
            v29.1h-7.8v-3.1c-0.6,1-1.5,1.9-2.6,2.4c-1.1,0.6-2.4,0.9-3.9,0.9c-1.8,0-3.3-0.4-4.8-1.4c-1.4-0.9-2.6-2.2-3.4-3.9
            c-0.8-1.7-1.3-3.7-1.3-6C115.9,24.9,116.2,22.9,117,21.2z M130.6,23.8c-0.7-0.8-1.7-1.2-2.9-1.2c-1.2,0-2.1,0.4-2.9,1.2
            s-1.1,1.9-1.1,3.3s0.4,2.5,1.1,3.3c0.7,0.8,1.7,1.3,2.9,1.3c1.1,0,2.1-0.4,2.9-1.2c0.7-0.8,1.2-1.9,1.2-3.4
            C131.7,25.7,131.3,24.6,130.6,23.8z" fill="#E83661"/>
        <path d="M146.4,36.2c-2.1-1.6-3.2-3.7-3.3-6.6h8.2c0.1,1,0.4,1.7,0.9,2.1s1.1,0.7,1.9,0.7c0.7,0,1.3-0.1,1.8-0.5
            c0.4-0.4,0.7-0.8,0.7-1.5c0-0.8-0.4-1.4-1.1-1.9c-0.7-0.4-2-1-3.7-1.5c-1.8-0.6-3.3-1.2-4.4-1.8c-1.1-0.6-2.1-1.4-2.9-2.4
            c-0.8-1.1-1.3-2.5-1.3-4.3s0.4-3.3,1.3-4.6s2.1-2.2,3.7-2.9s3.3-1,5.3-1c3.3,0,5.8,0.7,7.7,2.3c1.9,1.5,3,3.6,3.1,6.4H156
            c0-0.8-0.3-1.5-0.7-1.9c-0.5-0.4-1.1-0.6-1.9-0.6c-0.6,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.6,1.3s0.9,0.7,1.5,1
            c0.6,0.3,1.5,0.6,2.7,1c1.8,0.6,3.2,1.2,4.4,1.8c1.1,0.6,2.1,1.4,3,2.4c0.8,1,1.3,2.4,1.3,4c0,1.6-0.4,3.1-1.3,4.4
            c-0.8,1.3-2,2.4-3.6,3.1s-3.4,1.1-5.6,1.1C151.1,38.5,148.4,37.7,146.4,36.2z" fill="#E83661"/>
        <path d="M182.1,38.2l-6.1-9v9h-7.7V9.1h7.7v15.7l6.1-8.7h9.3l-8.7,11.1l8.8,11H182.1z" fill="#E83661"/>
        <rect x="193.7" y="16.2" width="7.7" height="22.1" fill="#E83661"/>
        <path d="M213.5,9.1v29.1h-7.7V9.1H213.5z" fill="#E83661"/>
        <path d="M225.6,9.1v29.1h-7.7V9.1H225.6z" fill="#E83661"/>
        <path d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M40.8,38.6h-7.6V23.7l-5.2,14.9h-6.3
        l-5.2-15v15H9.2V11.6h9.1L25,29.1l6.7-17.5h9.2V38.6z" fill="#E83661"/>
    </svg>
</template>
